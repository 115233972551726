import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import './content.scss'
import socialIcon from '../../images/ICON_SozialesEngagement.svg'
import fencaLogo from '../../images/LOGO_Fenca.png'

export const Content: FunctionComponent = () => <div>
  <Container className="companyContentContainer">
    <Row className="companyContentRow">
      <Col md={ { offset: 1, size: 9 }}>
        <span className="companyHeadline">Progress West. Die Inkassoexperten.</span>
      </Col>
      <Col md={ { offset: 1, size: 10 }} className="companyText">
        <span>Seit mehr als 40 Jahren ist Progress West ein erfolgreiches österreichisches Familienunternehmen. Als Inkassoexperten bieten wir Freiberuflern, kleinen und mittleren Unternehmen sowie Großkonzernen einen Rundum-Service für das Debitoren- und Forderungsmanagement. Unser Erfolgsgeheimnis ist seit jeher, der stetige Drang zur Weiterentwicklung im Sinne unseres hohen kundenorientierten Qualitätsanspruchs.</span>
      </Col>
      <Col md={ { size: 11 }} className="history">
        <span>Unsere Geschichte</span>
      </Col>
      <Col md={ { size: 11 }} className="secondHistory">
        <span>Progress West. Seit über 40 Jahren.</span>
      </Col>
    </Row>
    <Row className="companyContentRow">
      <Col md={ { offset: 1, size: 5 }} className="historyText">
        <div>
          <span> <span className="mainColor">1980:</span> Das Stammhaus Progress wurde in Innsbruck gegründet. Von Anfang an war klar, dass uns nur optimale technische Voraussetzungen unseren visionären Zielen näherbringen konnten. So wurde – neben dem Hauptgeschäft – der Fokus speziell auf moderne Kommunikation und Datenverarbeitung gesetzt.</span>
        </div>
        <div className="mt-3">
          <span> <span className="mainColor">1983:</span> Bereits jetzt waren unsere Partneranwälte online verbunden, zudem waren wir das erste Inkassounternehmen mit einer EDV-Großanlage und BTX, dem „Vorläufer“ des heutigen Internets. Diese umfassenden Dienstleistungen wurden durch ein eigenes Schwesterunternehmen, der Progress Data GmbH gewährleistet und gipfelten darin, dass eine eigene Branchensoftware in Form einer Ganzheitslösung entwickelt wurde. Ein „papierloses Büro“ ist bei uns seither nicht bloß eine Vision.</span>
        </div>
      </Col>
      <Col md={ { size: 5 }} className="historyText">
        <div>
          <span> <span className="mainColor">1988:</span> Die Gesellschaft wurde in Progress West – Inkassobüro und Auskunftei GmbH umbenannt.</span>
        </div>
        <div className="mt-3">
          <span> <span className="mainColor">1995:</span> Sitzverlegung nach Salzburg.</span>
        </div>
        <div className="mt-3">
          <span> <span className="mainColor">2009:</span> Das Unternehmen wurde in Progress West – Inkassoinstitut und Auskunftei GmbH umbenannt. Die Geschäftsführung obliegt Vincenz Mihelak.</span>
        </div>
        <div className="mt-3">
          <span> <span className="mainColor">Heute:</span> Der bisherige Erfolg bestätigt die Richtigkeit der gewählten Firmenpolitik. Und die Geschichte geht weiter ...</span>
        </div>
      </Col>
    </Row>
    <Row className="margin">
      <Col md={ { offset: 1, size: 11 }} className="quality">
        <span>Qualitätssicherung</span>
      </Col>
      <Col md={ { offset: 1, size: 11 }} className="secondQuality">
        <span>Progress West. Qualität als oberstes Prinzip.</span>
      </Col>
      <Col md={ { offset: 1, size: 11 }} className="qualityText">
        <Container>
          <div className="margin-left">
            <span>Mit mehr als 40 Jahren Erfahrung und ständiger Weiterentwicklung ist bei uns der Name Programm: Progress West. Denn unsere Mission ist Fortschritt – und Qualität das oberste Prinzip. Diese stellen wir sicher, indem wir sämtliche Prozesse schriftlich festhalten und gemäß der Standards laufend überprüfen. So können wir etwaige Schwächen sofort erkennen und verbessern. Unsere Qualitätsansprüche schließen auch Partnerschaften mit unseren Anwälten und Erfüllungsgehilfen mit ein, sodass die Professionalität unserer Dienstleistungen gewährleistet bleibt. Gelebte Teamarbeit und eine gesunde Feedbackkultur sowie permanente Weiterbildung und Entfaltungsmöglichkeiten unserer Mitarbeiter sind bei Progress West der Grundstein für konstruktives Arbeiten im Dienste unserer Kunden.</span>
          </div>
        </Container>
      </Col>
    </Row>
  </Container>
</div>
