import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import './legalInformationHeadline.scss'
import headImage from '../../images/salzburg.jpg'

export const LegalInformationHeadline: FunctionComponent = () => <Container className="realHomepageContentContainer">
  <Row className="g-0">
    <Col md={ { size: 6 }} className="homepageHeadlineContainer">
      <div className="homepageHeadlineText">
        <p className="homepageHeadlineTitle">Progress West.</p>
        <p>Impressum.</p>
      </div>
    </Col>
    <Col md={ { size: 6 }}>
      <div><img src={headImage} alt="reminderImage" className="homepageHeadImage"/></div>
    </Col>
  </Row>
</Container>
