import React from 'react'
import Header from '../header/header'
import { Footer } from '../footer/footer'
import { CompanyHeadline } from './companyHeadline'
import { Content } from './content'

export default function Company () {
  return (
    <div>
      <Header/>
      <CompanyHeadline/>
      <Content/>
      <Footer/>
    </div>
  )
}
