import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import './homepageHeadline.scss'
import headImage from '../../images/head.jpg'

export const HomepageHeadline: FunctionComponent = () => <Container className="realHomepageContentContainer">
  <Row className="g-0">
    <Col md={ { size: 6 }} className="homepageHeadlineContainer">
        <div className="homepageHeadlineText">
           <p>Progress West.</p>
            <p>Die Inkassoexperten.</p>
        </div>
    </Col>
    <Col md={ { size: 6 }}>
      <div><img src={headImage} alt="reminderImage" className="homepageHeadImage"/></div>
    </Col>
  </Row>
</Container>
