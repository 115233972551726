import React from 'react'
import Header from '../header/header'
import { Footer } from '../footer/footer'
import { LawyerHeadline } from './lawyerHeadline'
import { LawyerContent } from './lawyerContent'

export default function Lawyers () {
  return (
    <div>
      <Header/>
      <LawyerHeadline/>
      <LawyerContent/>
      <Footer/>
    </div>
  )
}
