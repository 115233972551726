import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import './lawyerHeadline.scss'
import headImage from '../../images/reminder.jpg'

export const LawyerHeadline: FunctionComponent = () => <div>
  <Container className="realHomepageContentContainer">
    <Row className="g-0">
      <Col md={ { size: 6 }} className="homepageHeadlineContainer">
        <div className="homepageHeadlineText">
            <p className="homepageHeadlineTitle">Progress West.</p>
            <p>Anwälte.</p>
        </div>
      </Col>
      <Col md={ { size: 6 }}>
        <img src={headImage} alt="headImage" className="homepageHeadImage" />
      </Col>
    </Row>
  </Container>
</div>
