import React from 'react'
import './App.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import Home from './components/homepage/home'
import Company from './components/company/company'
import LegalInformation from './components/legalInformation/legalInformation'
import Lawyers from './components/lawyers/lawyers'
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'

function App () {
  // eslint-disable-next-line no-undef
  function RequireAuth ({ children }: { children: JSX.Element }) {
    const token = sessionStorage.getItem('token')
    if (!token || token === '') {
      return <Navigate to="/login"/>
    }

    return children
  }

  return (
    <BrowserRouter>
      <Routes>
       <Route path="/" element={<Home/>} />
      <Route path="unternehmen" element={<Company />} />
      <Route path="impressum" element={<LegalInformation />} />
      <Route path="anwaelte" element={<Lawyers />} />
      </Routes>
     </BrowserRouter>
  )
}

export default App
