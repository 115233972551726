import Header from '../header/header'
import { Footer } from '../footer/footer'
import React from 'react'
import { HomepageHeadline } from './homepageHeadline'
import { Farewell } from './farewell'
import { Reminder } from './reminder'
import { Training } from './training'

export default function Home () {
  return (
    <div>
      <Header/>
      <HomepageHeadline/>
      <Farewell/>
      <Footer/>
    </div>
  )
}
