import React, { Component } from 'react'
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavLink,
  NavItem,
  Row,
  Col, DropdownToggle, DropdownItem
} from 'reactstrap'
import './header.scss'
import logo from '../../images/logo.png'
import login from '../../images/login.svg'

type State = {
  isOpen: boolean
}

class Header extends Component<{}, State> {
  constructor (props: any) {
    super(props)
    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false
    }
  }

  toggle () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  render () {
    return (
      <div>
        <Container className="headerContainer">
          <Row>
            <Navbar color="faded" expand="md" className="test">
              <Col md={{ size: 1 }} className="toogleCol">
                <NavbarToggler onClick={this.toggle}/>
              </Col>
              <Col md={{ size: 10 }} xl={{ size: 1 }} className="logoCol">
                <NavbarBrand href="/"><img src={logo} className="logo" alt="logo" /></NavbarBrand>
              </Col>
              <Col md={{ size: 1 }} className="loginCol">
                <NavLink href="/login">
                  <img src={login} alt="login" />
                </NavLink>
              </Col>

              <div className="headerList">
                <Collapse isOpen={this.state.isOpen} navbar className="justify-content-end">
                  <Nav navbar>
                    <NavItem className="nav-item, headerItem">
                      <NavLink className="headerItemSize" href="/unternehmen">Unternehmen</NavLink>
                    </NavItem>
                  </Nav>
                </Collapse>
              </div>
            </Navbar>
          </Row>
        </Container>
      </div>
    )
  }
}

export default Header
