import React from 'react'
import Header from '../header/header'
import { Footer } from '../footer/footer'
import { LegalInformationHeadline } from './legalInformationHeadline'
import { LegalInformationContent } from './legalInformationContent'

export default function LegalInformation () {
  return (
    <div>
      <Header/>
      <LegalInformationHeadline/>
      <LegalInformationContent/>
      <Footer/>
    </div>
  )
}
