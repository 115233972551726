import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import './legalInformationContent.scss'
import wko from '../../images/logo_wko.gif'

export const LegalInformationContent: FunctionComponent = () => <div>
  <Container className="legalInformationContentContainer">
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 9 }}>
        <span className="legalInformationHeadline">Progress West Inkassoinstitut und Auskunftei GmbH</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
        <Col md={ { offset: 1, size: 9 }}>
            <a href='https://www.wkoecg.at/ecg.aspx?firmaid=839a6139-e0bc-41f6-af74-c60f3b6c2a3d&aspxautodetectcookiesupport=1' target='_blank' rel='noopener noreferrer'><img src={wko} alt="wko-logo"/></a>
        </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 9 }}>
        <span className="legalInformationText">Der Betroffene hat ein Recht auf Widerruf und Löschung seiner Daten, sofern nicht andere Gesetze und Vorschriften dagegen sprechen.</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 9 }}>
        <span className="legalInformationText">Informationspflicht laut §5 Abs. 1 E-Commerce-Gesetz (ECG)</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Postanschrift:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">A-5026 Salzburg-Aigen, Ernst-Grein-Straße 5/UG</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">E-Mail:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">office(at)progresswest.at</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Firmensitz:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">Ernst-Grein-Straße 5/UG 5020 Salzburg AUSTRIA</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Firmenbuch-Nummer:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">FN 51932x</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">UID-Nummer:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">ATU31734905</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Verantwortlich:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">Progress West Inkassoinstitut und Auskunftei GmbH</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Datenschutzkoordinator:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">Vincenz Mihelak</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Gerichtsstand:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">Salzburg</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Geschäftsführer:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">Vincenz Mihelak</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Bankinstitut:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">Volksbank Oberösterreich AG, Pfarrgasse 5, 4600 Wels AUSTRIA</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">BIC:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">VBOEATWWOOE</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">IBAN:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">AT34 4480 0314 1116 0000</span>
      </Col>
    </Row>
    <Row className="legalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Herausgeber und Medieninhaber:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">Progress West Inkassoinstitut und Auskunftei GmbH A-5026 Salzburg-Aigen, Ernst-Grein-Straße 5/UG</span>
      </Col>
    </Row>
    <Row className="lastLegalInformationContentRow">
      <Col md={ { offset: 1, size: 4 }}>
        <span className="legalInformationTextBold">Grundlegende inhaltliche Richtung:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <span className="legalInformationText">Präsentation der Firma Progress West Inkassoinstitut und Auskunftei GmbH</span>
      </Col>
    </Row>
  </Container>
</div>
