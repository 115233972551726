import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import './farewell.scss'

export const Farewell: FunctionComponent = () => <div>
  <Container className="farewellContentContainer">
    <Row className="farewellRow">
      <Col md={ { offset: 1, size: 9 }}>
        <span className="farewellHeadLine">&bdquo;Farewell&quot; offene Forderungen</span>
      </Col>
      <Col md={ { offset: 1, size: 10 }} className="mt-4">
        <span className="headText">Wenn der Kunde nicht zahlt, bringen wir Ihnen das wohlverdiente Geld, von der Betragshöhe unabhängig
herein. Mit mehr als 40 Jahren Erfahrung und ständiger Weiterentwicklung ist der Name Programm: Progress
West. Denn unsere Mission ist Fortschritt - und <u className="underline">Qualität</u> das oberste Prinzip. Schenken Sie uns Ihr Vertrauen
und wir bieten Ihnen einen individuellen Rundum-Service bei Zahlungsproblemen. Damit offene Forderungen
effizient gelöst werden und Sie sich weiterhin auf das Wesentliche konzentrieren können: <u className="underline">Ihren Erfolg.</u></span>
      </Col>
    </Row>
  </Container>
</div>
