import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import './lawyerContent.scss'

export const LawyerContent: FunctionComponent = () => <div>
  <Container className="legalInformationContentContainer">
    <Row className="laywerContentRow">
      <Col md={ { offset: 1, size: 2 }}>
        <span className="laywerTextBold">Salzburg:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <a href='https://www.rechtsanwalt-bauer.at/' target='_blank' rel='noopener noreferrer'>Rechtsanwalt Dr. Hans-Peter Bauer</a>
      </Col>
    </Row>
    <Row className="laywerContentRow">
      <Col md={ { offset: 1, size: 2 }}>
        <span className="laywerTextBold">Wien:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <a href='https://www.hofmannlaw.at/de' target='_blank' rel='noopener noreferrer'>Rechtsanwalt Dr. Alexander Hofmann, LL.M.</a>
      </Col>
    </Row>
    <Row className="laywerContentRow">
      <Col md={ { offset: 1, size: 2 }}>
        <span className="laywerTextBold">Tirol:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <a href='https://www.hauska-matzunski.at/' target='_blank' rel='noopener noreferrer'>Hauska & Matzunski Rechtsanwälte</a>
      </Col>
    </Row>
    <Row className="lastLaywerContentRow">
      <Col md={ { offset: 1, size: 2 }}>
        <span className="laywerTextBold">Oberösterreich:</span>
      </Col>
      <Col md={ { size: 6 }}>
        <a href='https://www.wrsw.at/' target='_blank' rel='noopener noreferrer'>Wintersberger Rechtsanwälte GmbH</a>
      </Col>
    </Row>
  </Container>
</div>
