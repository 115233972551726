import React from 'react'
import ReactDOM from 'react-dom'
import './index.scss'

import App from './App'

const element = (
    <App />
)

const container = document.getElementById('root')

ReactDOM.render(element, container)
