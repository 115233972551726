import React, { FunctionComponent } from 'react'
import { Col, Container, Row } from 'reactstrap'
import whiteArrow from '../../images/whiteArrow.svg'
import './footer.scss'
import { Link } from 'react-router-dom'

export const Footer: FunctionComponent = () => <div>
  <Container className="footerContainer">
    <Row className="footerRow">
      <Col md={{ size: 2, offset: 1 }} className="footerItem">
        <img src={whiteArrow} alt="arrow" className="footerArrow"/>
         <Link to="/anwaelte">Anwälte</Link>
      </Col>
      <Col md={{ size: 2 }} className="footerItem">
        <img src={whiteArrow} alt="arrow" className="footerArrow"/>
        <a href='/files/datenschutz' target='_blank' rel='noopener noreferrer'>Datenschutz</a>
      </Col>
      <Col md={{ size: 2 }} className="footerItem">
        <img src={whiteArrow} alt="arrow" className="footerArrow"/>
         <Link to="/impressum">Impressum</Link>
      </Col>
    </Row>
    <Row className="footerPhone">
      <Col sn={{ size: 1, offset: 3 }} className="footerItem">
        <ul className="footerList">
          <li className="footerListElement">
            <img src={whiteArrow} alt="arrow" className="footerArrow"/>
            <Link to="/anwaelte">Anwälte</Link>
          </li>
          <li className="footerListElement">
            <img src={whiteArrow} alt="arrow" className="footerArrow"/>
            <a href='/files/datenschutz' target='_blank' rel='noopener noreferrer'>Datenschutz</a>
          </li>
          <li>
            <img src={whiteArrow} alt="arrow" className="footerArrow"/>
            <Link to="/impressum">Impressum</Link>
          </li>
        </ul>
      </Col>
    </Row>
  </Container>
</div>
